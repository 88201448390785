<template>
  <div class="modal-backdrop" @click="close">
    <div class="modal" @click.stop>
<!--      <header class="modal-header">-->
<!--        <slot name="header">-->
<!--        </slot>-->
<!--        <button-->
<!--          class="btn-close"-->
<!--          type="button"-->
<!--          @click="close"-->
<!--        >-->
<!--          x-->
<!--        </button>-->
<!--      </header>-->

      <section class="modal-body">
        <slot name="body">
          This is the default body!
        </slot>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 11;
}

.product-card__footer .modal {
  margin: unset;
  width: 100%;
  max-width: unset;
  border-radius: 30px 30px 0 0;
}

.modal-header {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-body {
  position: relative;
  padding: 20px 15px;
  overflow: auto;
  max-height: 100vh;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}
</style>
