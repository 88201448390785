<template>
  <div class="product-information">
    <div class="product-information__header">
      <div class="product-information__text title--second">
        {{ product.name }}
      </div>
      <div v-if="product.vote_sum" class="product-information__rating">
        <div class="product-information__rating-icon">
          <svg class="icon icon-star ">
            <use xlink:href="#star"></use>
          </svg>
        </div>
        <div class="product-information__rating-text">
          {{ product.vote_sum }}
        </div>
      </div>
      <svg class="icon icon-cross" @click="close">
        <use xlink:href="#cross"></use>
      </svg>
    </div>
  </div>
  <div class="product-slider">
    <div class="product-slider__favorite">
      <LikeButton
        :classItem="'like-btn--big'"
        :isFavourite="product.isFavoriteUser"
        :productId="mainProductId"
      />
    </div>
    <Slider v-if="product.pictureSlider.length > 0" :sliders="product.pictureSlider" :swiperOptions="swiperOptions"/>
    <LazyPicture
      v-else
      :alt="product.name"
      :src="product.picture"
      class="blur-up"
      src-placeholder="/img/no_img_src.png"
    />
  </div>
  <div class="container">
    <div class="product-information">
      <div :class="{ 'product-information__price--sale': product.isDiscount }" class="product-information__price ">
        <div class="product-information__price-num">
          {{ product.priceDiscount + " " + product.currency }}<span>/ шт</span>
        </div>
        <div v-if="product.isDiscount" class="product-information__price-old">
          {{ product.price + " " + product.currency }}
        </div>
      </div>
      <div v-if="product.quantity > 0" class="product-information__available">
        <div class="product-information__available-icon">
          <svg class="icon icon-square ">
            <use xlink:href="#square"></use>
          </svg>
        </div>
        <div class="product-information__available-text">
          В наличии<span class="product-information__available-span">({{ product.quantity }} шт)</span>
        </div>
      </div>
      <div v-else class="product-information__available">
        <div class="product-information__available-icon product-information__available-icon--not">
          <svg class="icon icon-cross ">
            <use xlink:href="#cross"></use>
          </svg>
        </div>
        <div class="product-information__available-text">Нет в наличии</div>
      </div>
      <div v-if="product.is_partner_product" class="product-information__buy-alt">
        <a v-if="product.partner_product_url" :href="product.partner_product_url" class="btn btn-standalone">
          Купить на сайте продавца
          <img alt="" class="icon" src="/img/link.svg"/>
        </a>

        <div v-if="product.partner_telegram && product.partner_whatsapp" class="buy-alt__available-text">Заказать товар
          в:
        </div>

        <div class="buy-alt__btn-group">
          <a  class="btn btn--outline telegram" :href="product.partner_telegram" v-if="product.partner_telegram">
            <svg class="icon telegram">
              <use xlink:href="#telegram"></use>
            </svg>
            Telegram
          </a>
          <a v-if="product.partner_whatsapp" :href="whatsappUrl" class="btn btn--outline whatsapp">
            <svg class="icon whatsapp">
              <use xlink:href="#whatsapp"></use>
            </svg>
            WhatsApp
          </a>
        </div>
      </div>
    </div>
    <div v-if="product.description" class="product-description">
      <div class="product-description__title title title--second">Описание</div>
      <div class="product-description__text" v-html="product.description"></div>
    </div>
  </div>

</template>

<script>
import Slider from "@/components/sliders/product";
import LikeButton from "@/components/button/like";

export default {
  components: {
    LikeButton,
    Slider,
  },

  props: {
    product: {},
  },

  data() {
    return {
      swiperOptions: {
        direction: "horizontal",
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
      },
      // product: {},
      counter: 1,
      currentOffer: 0,
      mainProductId: null,
      isButtonBasket: false,
      whatsappUrl: '',
    };
  },

  created() {
    // this.product = this.$store.state.catalog.product;
    this.mainProductId = this.product.id;
    //
    // if (this.product.offers.length > 0) {
    //   let offer = this.product.offers[0];
    //
    //   this.product = {...this.product, ...offer};
    // }

    this.whatsappUrl = this.product.partner_whatsapp + '?text=Здравствуйте, я хочу купить товар: ' + window.location.href
  },

  methods: {
    close() {
      this.$emit('close');
    },
    getSticker() {
      let sticker = [];

      if (this.product.stocks)
        sticker.push({
          'title': 'Акция',
          'class': 'product-feature--orange'
        });

      if (this.product.novelties)
        sticker.push({
          'title': 'Новинка',
          'class': 'product-feature--green'
        });

      if (this.product.sovetuem)
        sticker.push({
          'title': 'Советуем',
          'class': 'product-feature--purple'
        });

      if (this.product.hit)
        sticker.push({
          'title': 'Хит',
          'class': 'product-feature--blue'
        });

      if (this.product.preimushestva)
        sticker.push({
          'title': 'Преимущества',
          'class': 'product-feature--yellow'
        });

      if (this.product.is_partner_product)
        sticker.push({
          'title': 'Товар партнера',
          'class': 'product-feature--pink'
        });

      if (this.product.is_social_product)
        sticker.push({
          'title': 'Социальный товар',
          'class': 'product-feature--red'
        });

      return sticker;
    },
  },
};
</script>

<style lang="scss" scoped>

.product-information__header {
  align-items: center;

  .icon-cross {
    height: 30px;
    width: 30px;
    color: #E0E0E0;

    use {
      height: 25px;
      width: 25px;
    }
  }
}

.product-information {
  border: unset;
  margin: unset;
  padding-bottom: unset;
  --telegram: #0088CC;
  --whatsapp: #2CB742;

  .buy-alt__btn-group {
    .btn {
      .icon {
        width: 20px;
        height: 20px;
      }

      &.telegram:only-child {
        color: white;
        background-color: var(--telegram);

        &:before {
          content: 'Заказать товар в';
        }
      }

      &.whatsapp:only-child {
        color: white;
        background-color: var(--whatsapp);

        &:before {
          content: 'Заказать товар в';
        }
      }

      &:hover,
      &:hover:only-child {
        background-color: #FF980C;
        color: white;
      }
    }
  }
}
</style>
